import { memo } from "react"

import { IconProps } from "./types"
import { DEFAULT_NO_RFQ_SVG_SIZE, useNoRfqsIconColors } from "./utils"

export const NoLiveRfqsIcon = memo(function NoLiveRfqsIcon({
  size = DEFAULT_NO_RFQ_SVG_SIZE,
}: IconProps) {
  const { primary, contrast } = useNoRfqsIconColors()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M255.597 282.039H78.0164C75.2574 282.039 73 279.782 73 277.023V190.072C73 149.774 105.774 117 146.072 117H255.597C295.561 117 328 149.523 328 189.403V209.552C328 249.516 295.477 282.039 255.597 282.039ZM76.0328 279.007H255.597C290.043 279.007 324.967 253.5 324.967 209.636V189.487C324.967 147 289.959 120.116 255.597 120.116H146.072C111.292 120.116 78.0164 145.5 76.0328 190.156V279.007Z"
        fill={primary}
      />
      <path
        d="M199.915 220.593C184.197 220.593 171.405 207.802 171.405 192.084C171.405 176.366 184.197 163.574 199.915 163.574C215.633 163.574 228.424 176.366 228.424 192.084C228.424 207.802 215.633 220.593 199.915 220.593ZM199.915 166.69C188.329 166.69 174.438 174.5 174.438 192.167C174.438 204.871 185 217.644 199.915 217.644C216 217.644 225.392 204.871 225.392 192.167C225.392 175.5 211.5 166.69 199.915 166.69Z"
        fill={contrast}
      />
      <path
        d="M138.809 282.039C136.049 282.039 136.792 279.782 136.792 277.023V265.998C136.792 246.267 152.845 230.298 172.492 230.298H226.084C245.815 230.298 261.784 246.351 261.784 265.998V277.023C261.784 279.782 263.527 282.039 260.768 282.039C258.009 282.039 258.751 279.782 258.751 277.023V265.998C258.751 245 240.213 233.331 226.084 233.331H172.492C158.363 233.331 139.825 243.5 139.825 265.998V277.023C139.825 279.782 141.568 282.039 138.809 282.039Z"
        fill={primary}
      />
    </svg>
  )
})
