import { memo } from "react"

import { IconProps } from "./types"
import { DEFAULT_NO_RFQ_SVG_SIZE, useNoRfqsIconColors } from "./utils"

export const NoCancelledRfqsIcon = memo(function NoCancelledRfqsIcon({
  size = DEFAULT_NO_RFQ_SVG_SIZE,
}: IconProps) {
  const { primary, contrast } = useNoRfqsIconColors()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M226.754 203H174.246C163.824 203 155.343 194.518 155.343 184.097C155.343 173.675 163.824 165.194 174.246 165.194H226.754C237.175 165.194 245.657 173.675 245.657 184.097C245.657 194.518 237.175 203 226.754 203ZM174.246 169.12C169.016 169.12 159.269 171.979 159.269 184.097C159.269 195.617 169.023 199.073 174.246 199.073H226.754C231.984 199.073 241.73 195.617 241.73 184.097C241.73 172.576 231.984 169.12 226.754 169.12H174.246Z"
        fill={primary}
      />
      <path
        d="M305.288 92H95.712C93.1126 92 91 94.1126 91 96.712V140.691C91 143.291 93.1126 145.403 95.712 145.403H99.3089V303.005C99.3089 305.605 101.421 307.717 104.021 307.717H296.979C299.579 307.717 301.691 305.605 301.691 303.005V145.403H305.288C307.887 145.403 310 143.291 310 140.691V96.712C310 94.1126 307.895 92 305.288 92ZM94.9267 95.9267H306.081V141.476H302.484H98.5236H94.9267V95.9267ZM297.764 303.791H103.236V145.403H297.764V303.791Z"
        fill={primary}
      />
      <path
        d="M259.251 240.72H141.749C139.149 240.72 137.037 240.963 137.037 238.364C137.037 235.764 139.149 236.008 141.749 236.008H259.251C261.851 236.008 263.963 235.764 263.963 238.364C263.963 240.963 261.859 240.72 259.251 240.72Z"
        fill={contrast}
      />
      <path
        d="M259.251 265.089H141.749C139.149 265.089 137.037 265.332 137.037 262.733C137.037 260.133 139.149 260.377 141.749 260.377H259.251C261.851 260.377 263.963 260.133 263.963 262.733C263.963 265.332 261.859 265.089 259.251 265.089Z"
        fill={contrast}
      />
    </svg>
  )
})
