import { memo } from "react"

import { IconProps } from "./types"
import { DEFAULT_NO_RFQ_SVG_SIZE, useNoRfqsIconColors } from "./utils"

export const NoDoneRfqsIcon = memo(function NoDoneRfqsIcon({
  size = DEFAULT_NO_RFQ_SVG_SIZE,
}: IconProps) {
  const { primary, contrast } = useNoRfqsIconColors()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M199.76 331.52C127.107 331.52 68 272.413 68 199.76C68 182.693 71.2125 166.084 77.5584 150.394C87.2829 126.339 103.773 105.83 125.239 91.081C147.221 75.9838 172.992 68 199.76 68C272.413 68 331.52 127.107 331.52 199.76C331.52 272.413 272.413 331.52 199.76 331.52ZM199.76 70.9698C140.5 70.9698 98.5056 107.864 79.8003 154.136C73.937 168.632 70.9698 183.982 70.9698 199.76C70.9698 266.914 124.5 328 199.76 328.55C275.5 327.5 328.55 266.914 328.55 199.76C328.55 126 266.914 70.9698 199.76 70.9698Z"
        fill={primary}
      />
      <path
        d="M169.32 259.895C168.102 259.895 166.875 259.437 165.934 258.519C165.704 258.297 142.916 236.142 120.5 213.813C118.601 211.922 117.593 210.844 119.484 208.945C121.375 207.046 125.453 212.038 127.352 213.929C144.697 231.202 162.262 248.38 169.392 255.327C184.592 241.148 247.061 182.666 276.884 151.459C276.995 151.34 277.121 151.206 277.248 151.071C279.052 149.085 283.122 143.935 285.108 145.739C287.094 147.543 286.244 148.613 284.44 150.599C284.274 150.781 284.1 150.971 283.926 151.153C249.783 186.878 173.395 257.878 172.628 258.59C171.694 259.46 170.507 259.895 169.32 259.895Z"
        fill={contrast}
      />
    </svg>
  )
})
