import { memo } from "react"

import { IconProps } from "./types"
import { DEFAULT_NO_RFQ_SVG_SIZE, useNoRfqsIconColors } from "./utils"

export const NoExpiredRfqsIcon = memo(function NoExpiredRfqsIcon({
  size = DEFAULT_NO_RFQ_SVG_SIZE,
}: IconProps) {
  const { primary, contrast, secondary } = useNoRfqsIconColors()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M200.315 305.475C168.653 305.486 138.282 292.926 115.875 270.555C69.375 224.039 69.375 148.378 115.875 101.878C162.375 55.3779 238.044 55.3701 284.552 101.878C300.927 118.269 312.177 139.071 316.93 161.747C321.684 184.423 319.734 207.992 311.319 229.579C310.96 230.5 307.5 233 307.065 228.363C314.851 208.377 318.729 199.5 313.245 162.564C308.839 141.571 297.418 119.315 282.254 104.145C233.666 57 161.161 62.1114 118.111 105.145C72.5 149.5 76.0772 225.238 119.111 268.288C133.017 282.169 153.369 293.096 172.384 298.046C190.297 302.71 209.075 302.799 227 298.332C227 298.5 227 301 227 302.445C218.254 304.455 209.302 305.473 200.315 305.475Z"
        fill={primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202 107C200.895 107 200 107.895 200 109V196H202.569L202.836 196.265L203.099 196H204V195.094L245.726 153.16C246.505 152.377 246.502 151.111 245.719 150.332C244.936 149.553 243.669 149.556 242.89 150.339L204 189.423V109C204 107.895 203.105 107 202 107Z"
        fill={contrast}
      />
      <rect x="277" y="255" width="5" height="51" rx="2.5" fill={secondary} />
      <rect x="254" y="255" width="5" height="51" rx="2.5" fill={secondary} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266 219C258.268 219 252 225.268 252 233H229H225.5C224.119 233 223 234.119 223 235.5C223 236.881 224.119 238 225.5 238H229V309C229 320.598 238.402 330 250 330H284C295.598 330 305 320.598 305 309V238H306.5C307.881 238 309 236.881 309 235.5C309 234.119 307.881 233 306.5 233H305H282C282 225.268 275.732 219 268 219H266ZM234 238H300V309C300 317.837 292.837 325 284 325H250C241.163 325 234 317.837 234 309V238Z"
        fill={secondary}
      />
    </svg>
  )
})
